import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../utils/axios';
import Cookies from 'js-cookie';
import { Card, Row, Col, Divider, Statistic, List, Image, Collapse } from 'antd';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import calculateDaysLeft from '../utils/timeHelper';
import formatDate from '../utils/formatDate';
import API_BASE_URL from '../config';

const { Panel } = Collapse;

const OrderDetail = () => {
    const [order, setOrder] = useState(null);
    const [customer, setCustomer] = useState(null);
    const { orderId } = useParams();

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const token = Cookies.get('token');
                const response = await axiosInstance.get(`${API_BASE_URL}/api/orders/${orderId}`);
                setOrder(response.data);
            } catch (error) {
                console.error('Failed to fetch order:', error);
                // here you can also set an error state and show an error message to the user
            }
        };

        fetchOrder();
    }, [orderId]);

    const fetchCustomer = async () => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/api/orders/${orderId}/customer`);
            setCustomer(response.data);
        } catch (error) {
            console.error('Failed to fetch customer:', error);
        }
    };

    const handleCollapseChange = (key) => {
        if (key.includes("1") && !customer) {
            fetchCustomer();
        }
    };

    if (!order) {
        return 'Loading...';
    }

    const { daysLeft, color } = calculateDaysLeft(order.dueDate, order.dateOrderConfirmed);

    return (
        <Row gutter={16}>
            <Col span={24}>
                <Card title={`Comanda: ${order.title}`} bordered={false}>
                <Collapse defaultActiveKey={[]} onChange={handleCollapseChange}>
                        <Panel header="Detalii client" key="1">
                            {customer ?
                                <>
                                    <p>Denumire client: {customer.name}</p>
                                    <p>Telefon: {customer.phone}</p>
                                    <p>Adresa de email: {customer.email}</p>
                                    <p>Adresa de livrare: {customer.address}</p>
                                </>
                                : 'Loading...'
                            }
                        </Panel>
                    </Collapse>
                    <Divider />
                    <Row gutter={16}>
                        <Col span={6}>
                            <Statistic
                                title="Zile ramase finalizare comanda"
                                value={daysLeft}
                                prefix={<ClockCircleOutlined />}
                                suffix="zile"
                                valueStyle={{ color: color }}
                            />
                        </Col>
                        <Col span={6}>
                            <Statistic title="Data comanda confirmata" value={formatDate(order.dateOrderConfirmed)} prefix={<CalendarOutlined />} />

                        </Col>
                        <Col span={6}>
                            <Statistic title="Data limita" value={formatDate(order.dueDate)} prefix={<CalendarOutlined />} />

                        </Col>
                        <Col span={6}>
                            <Statistic title="Termen de realizare" value={order.numberOfDays} prefix={<ClockCircleOutlined />} suffix="days" />
                        </Col>
                    </Row>
                    <Divider />
                    <h3 style={{ fontStyle: 'italic' }}>
                        Mentiuni comanda: <strong>{order.orderMentions !== null && order.orderMentions !== "undefined" ? order.orderMentions : " - "}</strong>


                    </h3>

                    <h3 style={{ fontStyle: 'italic' }}>
                        Pret total comanda: <strong>{order.price ? order.price + " lei" : ""}</strong>
                    </h3>
                    <h2>Products:</h2>
                    <List
                        itemLayout="horizontal"
                        dataSource={order.products}
                        renderItem={(product, index) => (
                            <List.Item key={index}>
                                <List.Item.Meta
                                    title={product.name}
                                    description={
                                        <div>
                                            <p>Material: {product.material}</p>
                                            <p>Patina: {product.patina}</p>
                                            <p>Height: {product.height}</p>
                                            <p>Size: {product.size}</p>
                                            <p>Notes: {product.mentions}</p>
                                            <p>Status: {product.status}</p>
                                            <p>Pictures:</p>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <Image.PreviewGroup>
                                                    {product.pictures.map((picture, index) => (
                                                        <div
                                                            key={index}
                                                            style={{ width: '300px', maxHeight: '300px', overflow: 'hidden', marginRight: '10px' }}
                                                        >
                                                            <Image
                                                                style={{ objectFit: 'contain', objectPosition: '50% 50%', width: '100%', height: '100%' }}
                                                                src={`${API_BASE_URL}/${picture.path}/thumbnail/${picture.filename}`}
                                                            />
                                                        </div>
                                                    ))}
                                                </Image.PreviewGroup>
                                            </div>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Col>
        </Row>
    );
};

export default OrderDetail;
