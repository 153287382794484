import React, { useState, useEffect } from 'react';
import {
    Button,
    DatePicker,
    Form,
    Input,
    Row,
    Card,
    Divider,
    message,
    Col,
    Space,
    Upload,
    Select,
    InputNumber,
    Switch,
    Spin,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import axiosInstance from "../utils/axios";
import dayjs from 'dayjs';
import API_BASE_URL from '../config';

const { Dragger } = Upload;
const { TextArea } = Input;

const materialOptions = [
    {
        value: 'piatra-reconstituita',
        label: 'Piatra reconstituita',
    },
    {
        value: 'bronz',
        label: 'Bronz',
    },
    {
        value: 'praf-ceramic',
        label: 'Praf ceramic',
    },
    {
        value: 'marmura',
        label: 'Marmura',
    },
    {
        value: 'ipsos',
        label: 'Ipsos',
    },
    {
        value: 'materiale-compozite',
        label: 'Materiale compozite',
    },
    {
        value: 'rasina',
        label: 'Rasina',
    },
    {
        value: 'alama',
        label: 'Alama',
    },
    {
        value: 'metalex',
        label: 'Metalex',
    },
    {
        value: 'aluminiu',
        label: 'Aluminiu',
    },
];

const appendFormData = (formData, values, parentKey) => {
    if (values instanceof Object && !(values instanceof File)) {
        Object.keys(values).forEach(key => {
            appendFormData(formData, values[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        formData.append(parentKey, values);
    }
};

const onFinish = async (values, form) => {
    if (!values.products) {
        values.products = [];
    } else if (!Array.isArray(values.products)) {
        values.products = [values.products];
    }

    const formData = new FormData();

    appendFormData(formData, values);

    try {
        //console.log('formData: ', formData);
        for (const [key, value] of formData.entries()) {
            console.log(key, value);
        }
        const response = await axiosInstance.post(`${API_BASE_URL}/api/offers/add`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        message.success('Offer added successfully!');
        form.resetFields();
        console.log('Success:', response.data);
    } catch (error) {
        console.log('Error:', error);
    }
};

const onFinishFailed = (errorInfo) => {
    console.log('Failed: ', errorInfo);
};

const AddNewOffer = () => {
    const [offerDate, setOfferDate] = useState(dayjs());
    const [daysToFulfill, setDaysToFulfill] = useState(0);
    const [datePickerDisabled, setDatePickerDisabled] = useState(true);
    const [fulfillmentDate, setFulfillmentDate] = useState(dayjs());
    const [visible, setVisible] = useState(false);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [clientSelected, setClientSelected] = useState(false);
    const [customerLoading, setCustomerLoading] = useState(false);

    const [form] = Form.useForm();
    useEffect(() => {
        //console.log('fullfilmentDate: ', fulfillmentDate);
        //console.log('orderDate: ', orderDate);
        //setDaysToFulfill(fulfillmentDate.diff(orderDate, 'day'));
        //console.log('daysToFulfill: ', daysToFulfill);
    }, [daysToFulfill, fulfillmentDate, offerDate]);

    const loadCustomers = async () => {
        setCustomerLoading(true);
        const response = await axiosInstance.get(`${API_BASE_URL}/api/customers/getAll`);

        const options = response.data.map((customer) => ({
            label: customer.name, // Adjust these fields to match your customer object.
            value: customer._id,
            data: customer,
        }));

        setCustomerOptions(options);
        setCustomerLoading(false);
    };

    const handleCustomerChange = (value) => {
        if (value) {
            setClientSelected(true);
            const selectedCustomer = customerOptions.find(
                (option) => option.value === value
            );
            form.setFieldsValue({
                clientName: selectedCustomer.data.name,
                clientPhone: selectedCustomer.data.phone,
                clientEmail: selectedCustomer.data.email,
                clientAddress: selectedCustomer.data.address,
            });
        } else {
            setClientSelected(false);
            form.setFieldsValue({
                clientName: '',
                clientPhone: '',
                clientEmail: '',
                clientAddress: '',
            });
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    return (
        <>
            <h1>Adaugare oferta noua</h1>
            <Form
                name="addNewOrder"
                onFinish={(values) => onFinish(values, form)}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    offerDate: dayjs(),
                    daysToFulfill: 0,
                    fulfillmentDate: dayjs(),
                    products: [{}],
                }}
                onValuesChange={(changedValues, allValues) => {
                    if (changedValues.hasOwnProperty('offerDate') || changedValues.hasOwnProperty('daysToFulfill')) {
                        const offerDate = allValues.offerDate;
                        const daysToFulfill = allValues.daysToFulfill;
                        if (offerDate && daysToFulfill) {
                            const fulfillmentDate = dayjs(offerDate).add(daysToFulfill, 'day');
                            form.setFieldsValue({ fulfillmentDate });
                        }
                    } else if (changedValues.hasOwnProperty('fulfillmentDate')) {
                        const fulfillmentDate = changedValues.fulfillmentDate;
                        const offerDate = allValues.offerDate;
                        if (fulfillmentDate && offerDate) {
                            const daysToFulfill = fulfillmentDate.diff(offerDate, 'day');
                            form.setFieldsValue({ daysToFulfill });
                        }
                    }
                }}
                labelCol={{
                    span: 8
                }}
                wrapperCol={{
                    span: 16
                }}
                style={{
                    maxWidth: '100%',
                }}
            >
                <Row gutter={{ xs: 8, sm: 16, md: 24 }} >
                    <Col xs={24} sm={24} md={12}>
                        <Card title="Date oferta" style={{ marginBottom: '20px' }}>
                            <Form.Item
                                label="Denumire lucrare"
                                name="jobName"
                                rules={[{ required: true, message: 'Campul denumire lucrare este obligatoriu' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Mentiuni oferta"
                                name="orderMentions"
                            >
                                <TextArea rows={2} />
                            </Form.Item>

                            <Form.Item
                                label="Pret total oferta"
                                name="offerPrice"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Data oferta"
                                name="offerDate"
                            >
                                <DatePicker
                                    value={offerDate}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Termen de realizare (nr. zile)"
                                name="daysToFulfill"
                            >
                                <InputNumber
                                    value={daysToFulfill}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Alegere data livrare"
                            >
                                <Switch
                                    checked={!datePickerDisabled}
                                    onChange={() => {
                                        setDatePickerDisabled(!datePickerDisabled);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Data Livrare"
                                name="fulfillmentDate"
                            >
                                <DatePicker
                                    value={fulfillmentDate}
                                    disabled={datePickerDisabled}
                                />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col  xs={24} sm={24} md={12}>
                        <Card title="Date client" style={{ marginBottom: '20px' }}>
                            <Form.Item
                                label="Client existent"
                                name="customerId"
                            >
                                <Select
                                    options={customerOptions}
                                    onChange={handleCustomerChange}
                                    onFocus={loadCustomers}
                                    allowClear // to allow clearing the selection
                                    showSearch
                                    notFoundContent={customerLoading ? <Spin size="small" /> : null}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>

                            <Divider />

                            <Form.Item
                                label="Nume client"
                                name="clientName"
                            >
                                <Input disabled={clientSelected} />
                            </Form.Item>

                            <Form.Item
                                label="Telefon Client"
                                name="clientPhone"
                            >
                                <Input disabled={clientSelected} />
                            </Form.Item>

                            <Form.Item
                                label="Email client"
                                name="clientEmail"
                            >
                                <Input disabled={clientSelected} />
                            </Form.Item>

                            <Form.Item
                                label="Adresa Client"
                                name="clientAddress"
                            >
                                <Input disabled={clientSelected} />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24}>
                        <Card title="Produse" style={{ marginBottom: '20px' }}>
                            <Form.List name="products">
                                {(fields, { add, remove }) => (
                                    <>
                                        <Row gutter={16}>
                                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                                <Col key={key} xs={24} sm={12} md={8}>
                                                    <Card key={key} style={{ marginBottom: 16 }}>
                                                        <Space style={{ display: 'flex', flexDirection: 'column' }} align="start">
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'productName']}
                                                                fieldKey={[fieldKey, 'productName']}
                                                                rules={[{ required: true, message: 'Denumirea produsului este obligatorei!' }]}
                                                                label="Denumire produs"
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'material']}
                                                                fieldKey={[fieldKey, 'material']}
                                                                rules={[{ required: true, message: 'Materialul este obligatoriu!' }]}
                                                                label="Material"
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                            >
                                                                <Select
                                                                    showSearch
                                                                    placeholder="Selectati un material"
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    options={materialOptions}
                                                                />
                                                            </Form.Item>

                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'productPrice']}
                                                                fieldKey={[fieldKey, 'productPrice']}
                                                                rules={[{ required: false }]}
                                                                label="Pret lucrare"
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'productHeight']}
                                                                fieldKey={[fieldKey, 'productHeight']}
                                                                rules={[{ required: false }]}
                                                                label="Inaltime lucrare"
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'productSize']}
                                                                fieldKey={[fieldKey, 'productSize']}
                                                                rules={[{ required: false }]}
                                                                label="Dimensiuni lucrare"
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'patinaProdus']}
                                                                fieldKey={[fieldKey, 'patinaProdus']}
                                                                label="Patina"
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'productMentions']}
                                                                fieldKey={[fieldKey, 'productMentions']}
                                                                label="Mentiuni lucrare"
                                                                labelCol={{ span: 24 }}
                                                                wrapperCol={{ span: 24 }}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name={[name, 'images']}
                                                                valuePropName="fileList"
                                                                getValueFromEvent={e => {
                                                                    if (Array.isArray(e)) {
                                                                        return e;
                                                                    }
                                                                    return e && e.fileList;
                                                                }}
                                                                label="Images"
                                                            >
                                                                <Dragger
                                                                    name="file"
                                                                    customRequest={dummyRequest}
                                                                    accept="image/*"
                                                                    listType="picture"
                                                                    multiple={true}
                                                                    onDrop={e => {
                                                                        console.log('Dropped files', e.dataTransfer.files);
                                                                    }}
                                                                >
                                                                    <p className="ant-upload-drag-icon">
                                                                        <InboxOutlined />
                                                                    </p>
                                                                    <p className="ant-upload-text">Dati click sau trageti fisierele aici</p>
                                                                    <p className="ant-upload-hint">
                                                                        Se pot adauga unul sau mai multe fisiere
                                                                    </p>
                                                                </Dragger>
                                                            </Form.Item>

                                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                                        </Space>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                        <Col xs={24} sm={12} md={8}>
                                            <Card>
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        Add Product
                                                    </Button>
                                                </Form.Item>
                                            </Card>
                                        </Col>
                                    </>
                                )}
                            </Form.List>
                        </Card>
                    </Col>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
                </Row>
            </Form>
        </>
    );
}

export default AddNewOffer;