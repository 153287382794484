import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../utils/axios';
import { Card, Row, Col, Divider, List, Spin } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, HomeOutlined } from '@ant-design/icons';
import API_BASE_URL from '../config';

const CustomerDetail = () => {
    const [customer, setCustomer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { clientId } = useParams();

    useEffect(() => {
        const fetchCustomer = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.get(`${API_BASE_URL}/api/customers/${clientId}`);
                setCustomer(response.data);
            } catch (error) {
                console.error('Failed to fetch customer:', error);
                // here you can also set an error state and show an error message to the user
            } finally {
                setIsLoading(false);
            }
        };

        fetchCustomer();
    }, [clientId]);

    if (isLoading || !customer) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" />
            </div>
        );
    }

    return (

        <Row gutter={16}>
            <Col span={24}>
                <Card title={`Customer: ${customer.name}`} bordered={false}>
                    <p><UserOutlined /> Name: {customer.name}</p>
                    <p><MailOutlined /> Email: {customer.email}</p>
                    <p><PhoneOutlined /> Phone: {customer.phone}</p>
                    <p><HomeOutlined /> Address: {customer.address}</p>
                    <Divider />
                    <h2>Orders:</h2>
                    <List
                        itemLayout="horizontal"
                        dataSource={customer.orders}
                        renderItem={(order, index) => (
                            <List.Item key={index}>
                                <List.Item.Meta
                                    title={order.title}
                                    description={
                                        <div>
                                            <p>Date Ordered: {order.dateOrdered}</p>
                                            <p>Due Date: {order.dueDate}</p>
                                            <p>Status: {order.status}</p>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                    <Divider />
                    <h2>Offers:</h2>
                    <List
                        itemLayout="horizontal"
                        dataSource={customer.offers}
                        renderItem={(offer, index) => (
                            <List.Item key={index}>
                                <List.Item.Meta
                                    title={offer.title}
                                    description={
                                        <div>
                                            <p>Date: {offer.date}</p>
                                            <p>Status: {offer.status}</p>
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Col>
        </Row>
    );
};

export default CustomerDetail;
