import React, { useState, useEffect } from 'react';
import { Button, List, Modal, Form, Input, Radio, Select, Row, Col, message, Tooltip, TreeSelect } from 'antd';
import axiosInstance from '../utils/axios';
import API_BASE_URL from '../config';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  GlobalOutlined,
  AppstoreOutlined,
  TagsOutlined,
  CoffeeOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

const { Option } = Select;
const { TreeNode } = TreeSelect;

const AttributesPage = () => {
  const [attributes, setAttributes] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [editingAttribute, setEditingAttribute] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/api/attributes/getAll`);
        setAttributes(response.data);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchAttributes();
  }, []);

  const handleAddAttribute = () => {
    fetchCategories();
    setIsModalVisible(true);
  };

  const handleDeleteAttribute = (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this attribute?',
      onOk: async () => {
        try {
          await axiosInstance.delete(`${API_BASE_URL}/api/attributes/${id}`);
          setAttributes(attributes.filter(attribute => attribute._id !== id));
          message.success("Attribute deleted successfully");
        } catch (err) {
          console.error(err);
        }
      },
    });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        const payload = {
          name: values.attribute_name,
          unit: values.unit,
          ...(selectedType === "all_products" && { all_products: true }),
          ...(selectedType === "categories" && { categoryIds: values.categories }),
        };

        let response;
        if (editingAttribute) {
          response = await axiosInstance.put(`${API_BASE_URL}/api/attributes/${editingAttribute._id}`, payload);
          setAttributes(attributes.map((attribute) => (attribute._id === editingAttribute._id ? response.data : attribute)));
        } else {
          response = await axiosInstance.post(`${API_BASE_URL}/api/attributes`, payload);
          setAttributes([...attributes, response.data]);
        }

        message.success(`${editingAttribute ? 'Updated' : 'Added'} successfully.`);
        form.resetFields();
        setIsModalVisible(false);
        setSelectedType(null); // clear selected type
        setIsModalVisible(false);
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingAttribute(null);
  };

  const fetchCategories = async () => {
    try {
      const mainResponse = await axiosInstance.get(`${API_BASE_URL}/api/categories/getAll`);

      const mainCategories = mainResponse.data;

      const categories = mainCategories.map(mainCategory => {
        return {
          ...mainCategory,
          children: subCategories.filter(subCategory => subCategory.parent === mainCategory._id)
        };
      });

      setCategories(categories);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };
  
  const openEditModal = async (id) => {
    const response = await axiosInstance.get(`${API_BASE_URL}/api/attributes/${id}`);
    setEditingAttribute(response.data);
    
    if (!response.data.all_products) {
      setSelectedType("categories");
      await fetchCategories(); // fetch categories when editing an attribute that has associated categories
    } else {
      setSelectedType("all_products");
    }
  
    form.setFieldsValue({
      attribute_name: response.data.name,
      unit: response.data.unit,
      'radio-group': response.data.all_products ? 'all_products' : 'categories',
      categories: response.data.all_products ? [] : response.data.categoryIds // set category ids if not for all_products
    });
    setIsModalVisible(true);
  };

  return (
    <>
      <h1>Attributes</h1>
      <Button type="primary" icon={<PlusOutlined />} onClick={handleAddAttribute}>
        Add New
      </Button>
      <List
        dataSource={attributes}
        renderItem={item => {
          let icon;
          let iconTitle;

          if (item.all_products) {
            icon = <GlobalOutlined />;
            iconTitle = "All Products";
          } else if (item.category !== null) {
            icon = <AppstoreOutlined />;
            iconTitle = "Specific Category";
          } else if (item.productServices.length > 0) {
            icon = <CoffeeOutlined />;
            iconTitle = "Specific Product Service";
          } else {
            icon = <QuestionCircleOutlined />;
            iconTitle = "No Specific Association";
          }

          return (
            <List.Item>
              <Row style={{ width: '100%' }}>
                <Col span={16}>
                  <Tooltip title={iconTitle}>
                    {icon}
                  </Tooltip>
                  {item.name} ({item.unit})
                </Col>
                <Col span={4}>
                  <Button icon={<EditOutlined />} onClick={() => openEditModal(item._id)} />
                </Col>
                <Col span={4}>
                  <Button icon={<DeleteOutlined />} onClick={() => handleDeleteAttribute(item._id)} />
                </Col>
              </Row>
            </List.Item>
          );
        }}
      />
      <Modal title="Add Attribute" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item name="attribute_name" label="Attribute Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="unit" label="Measure Unit" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="radio-group" label="Select Type">
            <Radio.Group onChange={handleTypeChange}>
              <Radio value="all_products">All Products</Radio>
              <Radio value="categories">Categories</Radio>
            </Radio.Group>
          </Form.Item>
          {selectedType === "categories" && (
            <Form.Item name="categories" label="Categories">
              <TreeSelect
                showSearch
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                allowClear
                multiple
                treeDefaultExpandAll
                treeCheckable
              >
                {categories.map(category => (
                  <TreeNode value={category._id} title={category.name} key={category._id}>
                    {category.children.map(subCategory => (
                      <TreeNode value={subCategory._id} title={`-- ${subCategory.name}`} key={subCategory._id} />
                    ))}
                  </TreeNode>
                ))}
              </TreeSelect>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AttributesPage;