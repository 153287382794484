const calculateDaysLeft = (dueDate, dateOrderConfirmed) => {
    const daysLeft = Math.round((new Date(dueDate) - new Date()) / (1000 * 60 * 60 * 24));
    const numberOfDays = Math.round((new Date(dueDate) - new Date(dateOrderConfirmed)) / (1000 * 60 * 60 * 24));
    let color = 'green';
    const percentageLeft = daysLeft / numberOfDays;

    if (percentageLeft <= 1 / 3) {
        color = 'red';
    } else if (percentageLeft <= 2 / 3) {
        color = 'orange';
    }
    return { daysLeft, color };
}

export const dateDfference = (endDate, startDate) => {
    const numberOfDays = Math.round((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));
    return numberOfDays;
}

export default calculateDaysLeft;