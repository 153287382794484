import React, { useEffect, useState } from 'react';
import { Calendar, Badge, Alert, Card, Col, Row, Typography, Select, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import formatDate from '../utils/formatDate';
import axiosInstance from '../utils/axios';
import API_BASE_URL from '../config';
const { Title, Text } = Typography;

const CalendarPage = () => {
    const [orders, setOrders] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedDateOrders, setSelectedDateOrders] = useState([]);
    const [selectedDueOrders, setSelectedDueOrders] = useState([]);
    const [selectedDeliveredOrders, setSelectedDeliveredOrders] = useState([]);
    const [monthlyStats, setMonthlyStats] = useState({
        confirmed: 0,
        due: 0,
        delivered: 0,
    });

    useEffect(() => {
        // Fetch orders from the backend
        const fetchOrdersDates = async () => {
            await axiosInstance.get(`${API_BASE_URL}/api/orders/order-dates`) // Replace with your API endpoint
                .then(response => {
                    setOrders(response.data);
                    console.log(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
        }
        fetchOrdersDates();
    }, []);

    const onSelect = (value) => {
        setSelectedValue(value);
        const dateOrders = orders.filter(order => {
            console.log('order dateOrderReceived:', order.dateOrderConfirmed);
            return dayjs(order.dateOrderConfirmed).isSame(value, 'day')
        });
        const dueOrders = orders.filter(order => {
            console.log('order dueDate:', order.dueDate);
            return dayjs(order.dueDate).isSame(value, 'day')
        });
        const deliveredOrders = orders.filter(order => {
            if (order.dateDelivered) {
                console.log('order dateDelivered:', order.dateDelivered);
                return dayjs(order.dateDelivered).isSame(value, 'day');
            }
            return false;
        });
    
        setSelectedDateOrders(dateOrders);
        setSelectedDueOrders(dueOrders);
        setSelectedDeliveredOrders(deliveredOrders);
    };
    

    const onPanelChange = (value) => {
        const dateOrders = orders.filter(order => dayjs(order.dateOrderConfirmed).isSame(value, 'month')).length;
        const dueOrders = orders.filter(order => dayjs(order.dueDate).isSame(value, 'month')).length;
        const deliveredOrders = orders.filter(order => order.dateDelivered && dayjs(order.dateDelivered).isSame(value, 'month')).length;

        setMonthlyStats({
            confirmed: dateOrders,
            due: dueOrders,
            delivered: deliveredOrders,
        });
    };

    useEffect(() => {
        onPanelChange(dayjs());
        onSelect(dayjs());
    }, [orders]);

    const dateCellRender = (value) => {
        const dateOrders = orders.filter(order => dayjs(order.dateOrderConfirmed).isSame(value, 'day'));
        const dueOrders = orders.filter(order => dayjs(order.dueDate).isSame(value, 'day'));
        const deliveredOrders = orders.filter(order => order.dateDelivered && dayjs(order.dateDelivered).isSame(value, 'day'));

        return (
            <ul className="events">
                {dateOrders.map((order, index) => (
                    <li key={index}>
                        <Badge status="processing" text={`Comanda: ${order.title}`} />
                    </li>
                ))}
                {dueOrders.map((order, index) => (
                    <li key={index}>
                        <Badge status="warning" text={`Termen limita: ${order.title}`} />
                    </li>
                ))}
                {deliveredOrders.map((order, index) => (
                    <li key={index}>
                        <Badge status="success" text={`Delivered: ${order.title}`} />
                    </li>
                ))}
            </ul>
        );
    }

    const headerRender = ({ value, type, onChange }) => {
        const start = 0;
        const end = 12;
        const current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
            months.push(localeData.monthsShort(current.clone().month(i)));
        }

        const monthOptions = [];
        for (let i = 0; i < 12; i++) {
            monthOptions.push(
                <Select.Option className="month-item" key={`${i}`}>
                    {months[i]}
                </Select.Option>,
            );
        }
        const month = value.month();

        const year = value.year();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}
                </Select.Option>,
            );
        }
        return (
            <div style={{ padding: '10px' }}>
                <Row justify="space-between">
                    <Col>
                        <Typography.Title level={4}>
                            {value.format('MMMM')} {value.format('YYYY')}
                        </Typography.Title>
                    </Col>
                    <Col>
                        <Button type="primary" style={{ marginRight: '5px', backgroundColor: 'white', borderColor: 'grey', color: 'grey' }} onClick={() => onChange(value.subtract(1, type))}><LeftOutlined /></Button>
                        <Button type="primary" style={{ marginRight: '5px', backgroundColor: 'white', borderColor: 'grey', color: 'grey' }} onClick={() => onChange(value.add(1, type))}><RightOutlined /></Button>
                        <Select
                            size="small"
                            popupMatchSelectWidth={false}
                            className="my-year-select"
                            onChange={newYear => {
                                const now = value.clone().year(newYear);
                                onChange(now);
                            }}
                            value={String(year)}
                        >
                            {options}
                        </Select>
                        <Select
                            size="small"
                            popupMatchSelectWidth={false}
                            value={String(month)}
                            onChange={selectedMonth => {
                                const newValue = value.clone();
                                newValue.month(parseInt(selectedMonth, 10));
                                onChange(newValue);
                            }}
                        >
                            {monthOptions}
                        </Select>
                    </Col>
                </Row>
            </div>
        );
    };


    return (
        <div>
            <Row gutter={[16, 16]} justify="center">
                <Col xs={24} sm={12} md={8} lg={8}>
                    <Card title={<Text type="secondary">Total orders confirmed</Text>} bordered={false}>
                        <Title level={2}>{monthlyStats.confirmed}</Title>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
                    <Card title={<Text type="secondary">Total orders due</Text>} bordered={false}>
                        <Title level={2}>{monthlyStats.due}</Title>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
                    <Card title={<Text type="secondary">Total orders delivered</Text>} bordered={false}>
                        <Title level={2}>{monthlyStats.delivered}</Title>
                    </Card>
                </Col>
            </Row>
            <Alert
                message={`Events on ${selectedValue ? selectedValue.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}`}
                description={
                    <>
                        {selectedDateOrders.length > 0 && (
                            <>
                                <h4>Comanda confirmata</h4>
                                {selectedDateOrders.map((order, index) => (
                                    <p key={index}>
                                        <Link to={`/orders/${order._id}`}>{order.title}</Link>
                                    </p>
                                ))}
                            </>
                        )}
                        {selectedDueOrders.length > 0 && (
                            <>
                                <h4>Data limita</h4>
                                {selectedDueOrders.map((order, index) => (
                                    <p key={index}>
                                        <Link to={`/orders/${order._id}`}>{order.title}</Link>
                                    </p>
                                ))}
                            </>
                        )}
                        {selectedDeliveredOrders.length > 0 && (
                            <>
                                <h4>Data livrare</h4>
                                {selectedDeliveredOrders.map((order, index) => (
                                    <p key={index}>
                                        <Link to={`/orders/${order._id}`}>{order.title}</Link>
                                    </p>
                                ))}
                            </>
                        )}
                    </>
                }
                type="info"
            />
            <Calendar
                cellRender={dateCellRender}
                onSelect={onSelect}
                onPanelChange={onPanelChange}
                headerRender={headerRender}
            />
        </div>
    );
};

export default CalendarPage;