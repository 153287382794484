import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, message } from 'antd';
import axiosInstance from '../../utils/axios';
import API_BASE_URL from '../../config';

const { Option } = Select;

const EditCustomerForm = ({ customer, onCustomerUpdated }) => {
  const [formData, setFormData] = useState(customer);

  useEffect(() => {
    setFormData(customer);
  }, [customer]);

  const handleFormChange = (changedFields) => {
    setFormData({
      ...formData,
      ...changedFields
    });
  };

  const handleFormSubmit = async () => {
    try {
      const response = await axiosInstance.put(`${API_BASE_URL}/api/customers/update/${customer._id}`, formData);
      if (response.status === 200) {
        message.success('Customer updated successfully!');
        if (onCustomerUpdated) onCustomerUpdated(response.data);
      } else {
        throw new Error(`Server responded with status code ${response.status}`);
      }
    } catch (error) {
      console.error(error);
      message.error('An error occurred while updating the customer.');
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={formData}
      onValuesChange={handleFormChange}
      onFinish={handleFormSubmit}
    >
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input placeholder="Enter customer name" />
      </Form.Item>

      {/* You can customize this to support multiple phones and emails */}
      <Form.Item name="phones" label="Phones">
        <Input placeholder="Enter customer phone" />
      </Form.Item>

      <Form.Item name="emails" label="Emails">
        <Input placeholder="Enter customer email" />
      </Form.Item>

      <Form.Item name="address" label="Address">
        <Input placeholder="Enter customer address" />
      </Form.Item>

      <Form.Item name="delivery" label="Delivery">
        <Input placeholder="Enter delivery details" />
      </Form.Item>

      <Form.Item name="invoicing.type" label="Invoicing Type">
        <Select placeholder="Select invoicing type">
          <Option value="company">Company</Option>
          <Option value="person">Person</Option>
          <Option value="institution">Institution</Option>
        </Select>
      </Form.Item>

      {/* Depending on the type of invoicing you can show different fields */}
      {formData.invoicing?.type === 'company' && (
        <>
          <Form.Item name="invoicing.company.name" label="Company Name">
            <Input placeholder="Enter company name" />
          </Form.Item>

          <Form.Item name="invoicing.company.registrationNumber" label="Registration Number">
            <Input placeholder="Enter registration number" />
          </Form.Item>

          <Form.Item name="invoicing.company.vatNumber" label="VAT Number">
            <Input placeholder="Enter VAT number" />
          </Form.Item>

          <Form.Item name="invoicing.company.address" label="Company Address">
            <Input placeholder="Enter company address" />
          </Form.Item>
        </>
      )}

      {formData.invoicing?.type === 'person' && (
        <>
          <Form.Item name="invoicing.person.name" label="Person Name">
            <Input placeholder="Enter person name" />
          </Form.Item>

          <Form.Item name="invoicing.person.address" label="Person Address">
            <Input placeholder="Enter person address" />
          </Form.Item>

          <Form.Item name="invoicing.person.idSerialNumber" label="ID Serial Number">
            <Input placeholder="Enter ID serial number" />
          </Form.Item>
        </>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit">Update</Button>
      </Form.Item>
    </Form>
  );
};

export default EditCustomerForm;
