import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axios';
import { Card, Form, Input, Button, message, Row, Col, List, Image } from 'antd';
import API_BASE_URL from '../config';

const EditOrder = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);
  
    useEffect(() => {
      const fetchOrder = async () => {
        try {
          const response = await axiosInstance.get(`${API_BASE_URL}/api/orders/${orderId}`);
          setOrder(response.data);
          form.setFieldsValue({
            title: response.data.title,
            numeClient: response.data.numeClient,
            telefonClient: response.data.telefonClient,
            emailClient: response.data.emailClient,
            adresaLivrareClient: response.data.adresaLivrareClient,
            numberOfDays: response.data.numberOfDays,
            orderMentions: response.data.orderMentions,
            // Add more fields as per your requirement.
          });
          setLoading(false);
        } catch (error) {
          console.error('Failed to fetch order:', error);
          setLoading(false);
          message.error('Failed to fetch order');
        }
      };
      fetchOrder();
    }, [orderId, form]);
  
    const onFinish = async (values) => {
      try {
        await axiosInstance.patch(`${API_BASE_URL}/api/orders/edit/${orderId}`, values);
        message.success('Order updated successfully');
        navigate(`/orders/${orderId}`);
      } catch (error) {
        console.error('Failed to update order:', error);
        message.error('Failed to update order');
      }
    };
  
    if (loading) {
      return 'Loading...';
    }
  
    return (
      <Row justify="center">
        <Col span={12}>
          <Card title="Edit Order" bordered={false}>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                label="Order Title"
                name="title"
                rules={[{ required: true, message: 'Please input the title!' }]}
              >
                <Input />
              </Form.Item>
  
              <Form.Item
                label="Client Name"
                name="numeClient"
                rules={[{ required: true, message: 'Please input the client name!' }]}
              >
                <Input />
              </Form.Item>
  
              <Form.Item
                label="Client Phone"
                name="telefonClient"
                rules={[{ required: true, message: 'Please input the client phone!' }]}
              >
                <Input />
              </Form.Item>
  
              <Form.Item
                label="Client Email"
                name="emailClient"
                rules={[{ required: true, message: 'Please input the client email!' }]}
              >
                <Input />
              </Form.Item>
  
              <Form.Item
                label="Delivery Address"
                name="adresaLivrareClient"
                rules={[{ required: true, message: 'Please input the delivery address!' }]}
              >
                <Input />
              </Form.Item>
  
              <Form.Item
                label="Number of Days"
                name="numberOfDays"
                rules={[{ required: true, message: 'Please input the number of days!' }]}
              >
                <Input />
              </Form.Item>
  
              <Form.Item
                label="Order Mentions"
                name="orderMentions"
              >
                <Input.TextArea />
              </Form.Item>
  
              <Form.Item label="Products">
                <List
                  itemLayout="horizontal"
                  dataSource={order.products}
                  renderItem={(product, index) => (
                    <List.Item key={index}>
                      <List.Item.Meta
                        title={product.name}
                        description={
                          <div>
                            <p>Material: {product.material}</p>
                            <p>Patina: {product.patina}</p>
                            <p>Height: {product.height}</p>
                            <p>Size: {product.size}</p>
                            <p>Notes: {product.mentions}</p>
                            <p>Status: {product.status}</p>
                            <p>Pictures:</p>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              <Image.PreviewGroup>
                                {product.pictures.map((picture, index) => (
                                  <div
                                    key={index}
                                    style={{ width: '300px', maxHeight: '300px', overflow: 'hidden', marginRight: '10px' }}
                                  >
                                    <Image
                                      style={{ objectFit: 'contain', objectPosition: '50% 50%', width: '100%', height: '100%' }}
                                      src={`${API_BASE_URL}/${picture.path}/thumbnail/${picture.filename}`}
                                    />
                                  </div>
                                ))}
                              </Image.PreviewGroup>
                            </div>
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Form.Item>
  
              {/* Add more form fields as required */}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  };
  
  export default EditOrder;
  