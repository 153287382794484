import React, { useState, useEffect } from 'react';
import { Button, List, Modal, Form, Input, Select, Radio } from 'antd';
import axiosInstance from '../utils/axios';
import API_BASE_URL from '../config';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAttributeModalVisible, setIsAttributeModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [tempAttributes, setTempAttributes] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [disabledAttributes, setDisabledAttributes] = useState([]);

  const [form] = Form.useForm();
  const [attributeForm] = Form.useForm();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/api/productServices/getAll`);
        setProducts(response.data);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchProducts();
  }, []);

  const handleAddProduct = () => {
    fetchCategoriesAndAttributes();
    setIsModalVisible(true);
  };

  const fetchCategoriesAndAttributes = async () => {
    try {
      const categoriesResponse = await axiosInstance.get(`${API_BASE_URL}/api/categories/getAll`);
      const attributesResponse = await axiosInstance.get(`${API_BASE_URL}/api/attributes/getAllGlobalAttributes`);

      setCategories(categoriesResponse.data);
      setAttributes(attributesResponse.data);
      setDisabledAttributes(attributesResponse.data.map(attribute => attribute._id)); // Set disabled attribute ids
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleCategoryChange = async (value) => {
    try {
      const categoryAttributesResponse = await axiosInstance.get(`${API_BASE_URL}/api/categories/${value}/attributes`);
      const categoryAttributes = categoryAttributesResponse.data;

      setAttributes(prevAttributes => [...prevAttributes, ...categoryAttributes]);
      setDisabledAttributes(prevDisabledAttributes => [
        ...prevDisabledAttributes,
        ...categoryAttributes.map(attribute => attribute._id)
      ]);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleAddAttribute = () => {
    setIsAttributeModalVisible(true);
  };

  const handleEditProduct = async (id) => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/api/productServices/${id}`);
      setSelectedProduct(response.data);
      form.setFieldsValue({
        name: response.data.name,
        type: response.data.type,
        category: response.data.category,
      });
      setEditMode(true);
      setIsModalVisible(true);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteProduct = (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this product?',
      onOk: async () => {
        try {
          await axiosInstance.delete(`${API_BASE_URL}/api/productServices/${id}`);
          setProducts(products.filter(product => product._id !== id));
        } catch (err) {
          console.error(err);
        }
      },
    });
  };

  const handleOk = async () => {
    form
      .validateFields()
      .then(async (values) => {
        const payload = {
          name: values.name,
          type: values.type,
          category: values.category,
        };

        // Create each temporary attribute in the database and collect their ids
        const attributeIds = [];
        for (const attribute of tempAttributes) {
          const response = await axiosInstance.post(`${API_BASE_URL}/api/attributes`, attribute);
          attributeIds.push(response.data._id);
        }

        // Add the attribute ids to the request to create the product
        payload.attributes = attributeIds;

        try {
          let response;
          if (editMode) {
            response = await axiosInstance.put(`${API_BASE_URL}/api/productServices/${selectedProduct._id}`, payload);
            setProducts(products.map(product => product._id === selectedProduct._id ? response.data : product));
          } else {
            response = await axiosInstance.post(`${API_BASE_URL}/api/productServices`, payload);
            setProducts([...products, response.data]);
          }
          form.resetFields();
          setIsModalVisible(false);

          // Clear the temporary attributes
          setTempAttributes([]);
          setEditMode(false);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
  };

  const handleAttributeModalOk = () => {
    attributeForm
      .validateFields()
      .then(async (values) => {
        const newAttribute = {
          name: values.attributeName,
          unit: values.attributeUnit,
        };

        setTempAttributes([...tempAttributes, newAttribute]);

        // set the new attribute in the main form's 'attributes' field
        const currentAttributes = form.getFieldValue("attributes") || [];
        form.setFieldsValue({
          attributes: [...currentAttributes, newAttribute.name],
        });

        attributeForm.resetFields();
        setIsAttributeModalVisible(false);
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAttributeModalCancel = () => {
    setIsAttributeModalVisible(false);
  };

  return (
    <>
      <h1>Products and Services</h1>
      <Button type="primary" icon={<PlusOutlined />} onClick={handleAddProduct}>
        Add New
      </Button>
      <List
        dataSource={products}
        renderItem={item => (
          <List.Item>
            <div>{item.name}</div>
            <div>{item.type}</div>
            <Button icon={<EditOutlined />} onClick={() => handleEditProduct(item._id)}>
              Edit
            </Button>
            <Button icon={<DeleteOutlined />} onClick={() => handleDeleteProduct(item._id)}>
              Delete
            </Button>
          </List.Item>
        )}
      />

      <Modal title="Add Product/Service" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          {/* Product/Service Name */}
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
            <Input />
          </Form.Item>

          {/* Product/Service Type */}
          <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select the type!' }]}>
            <Radio.Group>
              <Radio value="product">Product</Radio>
              <Radio value="service">Service</Radio>
            </Radio.Group>
          </Form.Item>

          {/* Category */}
          <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Please select the category!' }]}>
            <Select onChange={handleCategoryChange}>
              {categories.map(category => (
                <Option key={category._id} value={category._id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Attributes */}
          <Form.Item
  name="attributes"
  label="Attributes"
  rules={[{ required: true, message: 'Please select the attributes!' }]}
>
  <Select mode="multiple" defaultValue={disabledAttributes.map(attribute => attribute._id)}>
    {/* Render the disabled attributes */}
    {disabledAttributes.map((disabledAttribute) => (
      <Option key={disabledAttribute._id} value={disabledAttribute._id} disabled>
        {disabledAttribute.name}
      </Option>
    ))}
    {/* Render the other attributes */}
    {attributes.map((attribute) => (
      <Option
        key={attribute._id}
        value={attribute._id}
      >
        {attribute.name}
      </Option>
    ))}
  </Select>
</Form.Item>

          <Button onClick={handleAddAttribute}>Add New Attribute</Button>
        </Form>
      </Modal>

      <Modal title="Add Attribute" open={isAttributeModalVisible} onOk={handleAttributeModalOk} onCancel={handleAttributeModalCancel}>
        <Form form={attributeForm} layout="vertical">
          <Form.Item name="attributeName" label="Attribute Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="attributeUnit" label="Attribute Unit" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProductsPage;
