//write a simple page to test a custom calendar
//site must use antd    

import React from 'react';
import Calendar from '../components/Calendar';

const TestCalendar = () => {
    return (
        <div>
        <Calendar />
        </div>
    );
}

export default TestCalendar;