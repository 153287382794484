import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import axiosInstance from "../utils/axios";
import axios from "axios";
import API_BASE_URL from '../config';
import UAParser from 'ua-parser-js';

import setDeviceName from '../utils/setDevice';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [device, setDevice] = useState(setDeviceName());

    // Check user authentication status when the component mounts
    useEffect(() => {
        const deviceName = setDeviceName();
        setDevice(deviceName);
        console.log('setting device name');
    }, []);

    useEffect(() => {
        if (device) {
            console.log('device name ' + device);
            console.log('checking and refreshing access token');
            const refreshAndCheckAuth = async () => {
                await refreshAccessToken();
                checkAuth();
            }
            refreshAndCheckAuth();
        }
    }, [device]);

    const signup = async (email, password, name, role, navigate) => {
        try {
            await axios.post(`${API_BASE_URL}/api/auth/signup`, { email, password, name, role });
            // after signup, navigate to login page
            navigate('/login');
        } catch (err) {
            setError(err);
        }
    };

    const login = async (email, password, navigate) => {
        try {
            const response = await axiosInstance.post(`${API_BASE_URL}/api/auth/login`, {
                email,
                password,
                device
            });

            if (response.status === 200) {
                let { token, refreshToken, user } = response.data;
                const accessExpiration = 30; // 1 hour
                const refreshExpiration = 30; // 30 days
                if (!token || !refreshToken) {
                    token = Cookies.get("token");
                    refreshToken = Cookies.get("refreshToken");
                } else {
                    Cookies.set("token", token, { expires: accessExpiration });
                    Cookies.set("refreshToken", refreshToken.token, { expires: refreshExpiration });
                }
                if (token) {
                    //const decoded = jwt_decode(token);
                    setUser(user);
                    setIsAuthenticated(true);
                    navigate('/orders');
                }
                return { success: true };// navigate to dashboard after login
            } else {
                return { success: false, error: "Unexpected error occurred." };
            }
        } catch (err) {
            return { success: false, error: err.response?.data?.message || "Error logging in." };
        }
    };

    const logout = async (navigate) => {
        try {
            console.log('logging out from device ' + device);
            await axiosInstance.post("/auth/logout", { device });
            Cookies.remove('token');
            Cookies.remove('refreshToken');
            setUser(null);
            setIsAuthenticated(false);
            navigate('/login'); // navigate to login page after logout
        } catch (err) {
            setError(err);
        }
    };

    const refreshAccessToken = async () => {
        try {
            const token = Cookies.get('token');
            const refreshToken = Cookies.get('refreshToken');
            if (!refreshToken) {
                return { success: false, error: "Please login again.." };
            }

            if (!token || token === 'undefined') {
                return { success: false, error: "Please login again.." };
            }

            const decoded = jwt_decode(token);
            const currentTime = Date.now() / 1000;

            if (decoded.exp < currentTime) {

                try {
                    const response = await axiosInstance.post(
                        `${API_BASE_URL}/api/auth/refresh-token`,
                        { refreshToken, oldToken: token, device },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    const newToken = response.data.token;

                    if (newToken) {
                        Cookies.set("token", response.data.token);
                        Cookies.set("refreshToken", response.data.refreshToken);
                    }
                } catch (error) {
                    console.error("Error refreshing token:", error);
                }
            }
        } catch (error) {
            return { success: false, error: error.response?.data?.message || "Error refreshing access token." };
        }
    };
/*
    const setDeviceName = async () => {
        const parser = new UAParser();
        const result = parser.getResult();
        const deviceName = {
            name: result.device.model,
            type: result.device.type,
            browserName: result.browser.name,
            browserVersion: result.browser.version,
            os: result.os.name,
            osVersion: result.os.version,
            cpuArchitecture: result.cpu.architecture
        };

        const device = deviceName.name + '-' + deviceName.type + '-' + deviceName.browserName + '-' + deviceName.browserVersion + '-' + deviceName.os + '-' + deviceName.osVersion + '-' + deviceName.cpuArchitecture;
        setDevice(device);
    };
*/
    const checkAuth = async () => {
        try {
            const token = Cookies.get('token');
            if(token) {
                const response = await axiosInstance.post("/auth/get-data-from-token")
                // Assuming response.data returns user object
                console.log("response: " + JSON.stringify(response.data.user));
                
                setUser(response.data.user);
                setIsAuthenticated(true);
            }
            setIsLoading(false);
        } catch (err) {
            console.log("error: " + err)
            setError(err);
            setIsLoading(false);
        }
    };


    const value = {
        isAuthenticated,
        user,
        error,
        isLoading,
        device,
        signup,
        login,
        logout,
        refreshAccessToken
    };

    return <AuthContext.Provider value={value}>
        {!isLoading && children}
    </AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
