import React, { useState, useEffect } from 'react';
import { BookOutlined, DesktopOutlined, HomeOutlined, TeamOutlined, UserOutlined, DatabaseOutlined, DollarOutlined, SettingOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Avatar, Layout, Menu, Divider, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const { Sider } = Layout;

const AppSider = ({ collapsed, setCollapsed }) => {
  const { isAuthenticated, user, logout } = useAuth();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loggedIn, setLoggedIn] = useState(isAuthenticated);
  const [email, setEmail] = useState(user && user.email ? user.email : '');

  let navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setCollapsed(false);
      } else {
        setCollapsed(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setLoggedIn(isAuthenticated);
    setEmail(user && user.email ? user.email : '');
  }, [isAuthenticated, user]);

  const menuItems = [
    {
      label: 'Home',
      key: 'main1',
      icon: <HomeOutlined />,
      onClick: () => {
        navigate('/')
      }
    },
    {
      label: 'Comenzi',
      key: 'sub1',
      icon: <DesktopOutlined />,
      children: [
        {
          key: '1',
          label: 'Toate comenzile',
          onClick: () => {
            console.log('toate comenzile');
            navigate('/orders')
          }
        },
        {
          key: '2',
          label: 'Adaugare comanda noua',
          onClick: () => {
            console.log('adaugare comanda noua');
            navigate('/addNewOrder')
          }
        },
        {
          key: '3',
          label: 'Calendar',
          onClick: () => {
            console.log('Calendar comenzi');
            navigate('/calendar');
          }
        },
        {
          key: '111',
          label: 'Comenzi livrate',
          onClick: () => {
            console.log('Comenzi livrate');
            navigate('/orders/delivered')
          }
        },
      ]
    },
    {
      label: 'Utilizatori',
      key: 'sub3',
      icon: <UserOutlined />,
      children: [
        {
          key: '4',
          label: 'Toti utilizatorii',
          onClick: () => {
            console.log('Toti utilizatorii');
            navigate('/users');
          }
        },
        {
          key: '5',
          label: 'Permisiuni',
          onClick: () => {
            console.log('Permisiuni');
            navigate('/permissions');
          }
        },
        {
          key: '6',
          label: 'Roluri',
          onClick: () => {
            console.log('Roluri');
            navigate('/roles');
          }
        },
      ]
    },
    {
      label: 'Clienti',
      key: 'sub4',
      icon: <TeamOutlined />,
      children: [
        {
          key: '7',
          label: 'Clienti',
          onClick: () => {
            console.log('Clienti');
            navigate('/clients');
          }
        },
        {
          key: '8',
          label: 'Oferte',
          onClick: () => {
            console.log('Oferte');
            navigate('/offers');
          }
        },
        {
          key: '81',
          label: 'Adaugare Oferta',
          onClick: () => {
            console.log('Adauga Oferta');
            navigate('/add-new-offer');
          }
        },
      ]
    },
    {
      label: 'Catalog',
      key: 'sub10',
      icon: <BookOutlined />,
      children: [
        {
          key: '18',
          label: 'Categorii',
          onClick: () => {
            console.log('Categorii');
            navigate('/catalog/categorii');
          }
        },
        {
          key: '16',
          label: 'Produse',
          onClick: () => {
            console.log('Produse');
            navigate('/catalog/produse');
          }
        },
        {
          key: '17',
          label: 'Servicii',
          onClick: () => {
            console.log('Servicii');
            navigate('/catalog/servicii');
          }
        },
        {
          key: '19',
          label: 'Atribute',
          onClick: () => {
            console.log('Atribute');
            navigate('/catalog/atribute');
          }
        },
      ]
    },
    {
      label: 'Inventar',
      key: 'sub5',
      icon: <DatabaseOutlined />,
      children: [
        {
          key: '9',
          label: 'Produse',
          onClick: () => {
            console.log('Produse');
            navigate('/products');
          }
        },
        {
          key: '10',
          label: 'Materii Prime',
          onClick: () => {
            console.log('Materii Prime');
            navigate('/rawMaterials');
          }
        },
      ]
    },
    {
      label: 'Financiar',
      key: 'sub6',
      icon: <DollarOutlined />,
      children: [
        {
          key: '11',
          label: 'Venituri',
          onClick: () => {
            console.log('Venituri');
            navigate('/income');
          }
        },
        {
          key: '12',
          label: 'Cheltuieli',
          onClick: () => {
            console.log('Cheltuieli');
            navigate('/expenses');
          }
        },
      ]
    },
    {
      label: 'Setari',
      key: 'sub7',
      icon: <SettingOutlined />,
      children: [
        {
          key: '13',
          label: 'Profil',
          onClick: () => {
            console.log('View/Edit Profile');
            navigate('/settings/profile');
          }
        },
        {
          key: '14',
          label: 'Permisiuni',
          onClick: () => {
            console.log('View Permissions');
            navigate('/settings/permissions');
          }
        },
        {
          key: '15',
          label: 'Schimbare parola',
          onClick: () => {
            console.log('Change Password');
            navigate('/settings/password');
          }
        },
      ]
    },
  ];
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        position: 'fixed',
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '48px', // extra padding to compensate for the trigger
        marginBottom: '48px' // extra padding to compensate for the trigger
      }}
    >
      <div style={{ textAlign: 'center', margin: '20px' }}>
  {loggedIn ? (
    <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }}>{user && user.name ? user.name[0] : ''}</Avatar>
  ) : (
    <a href="/login">
      <UserOutlined style={{ fontSize: '30px', color: '#08c' }} />
    </a>
  )}
</div>

      <Divider style={{ margin: '0px', color: '#fff' }} />

      <div style={{ flexGrow: 1 }}> {/* This div will expand to take all available space */}
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={menuItems} />
      </div>

      {loggedIn && (
  <div style={{ padding: '20px', paddingBottom: '68px' }}>
    <Button 
      type="primary" 
      icon={<PoweroffOutlined />}
      onClick={() => logout(() => navigate("/login"))}
    >
      {!collapsed && 'Logout'}
    </Button>
  </div>
)}
    </Sider>
  );


}

export default AppSider;