import UAParser from 'ua-parser-js';

const setDeviceName = () => {
    const parser = new UAParser();
    const result = parser.getResult();
    const deviceName = {
        name: result.device.model,
        type: result.device.type,
        browserName: result.browser.name,
        browserVersion: result.browser.version,
        os: result.os.name,
        osVersion: result.os.version,
        cpuArchitecture: result.cpu.architecture
    };

    const device = deviceName.name + '-' + deviceName.type + '-' + deviceName.browserName + '-' + deviceName.browserVersion + '-' + deviceName.os + '-' + deviceName.osVersion + '-' + deviceName.cpuArchitecture;
    return(device);
};

export default setDeviceName;