import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Statistic, Typography } from 'antd';
import axiosInstance from '../utils/axios';
import Cookies from 'js-cookie';
import API_BASE_URL from '../config';
import Chart from "react-apexcharts";

const { Title, Paragraph } = Typography;

const Home = () => {
  const [stats, setStats] = useState({
    totalOrders: 0,
    ordersDueThisMonth: 0,
    ordersDueUnder10Days: 0,
    ordersDueUnder30Days: 0,
    ordersDueUnder60Days: 0,
    totalOrdersThisMonth: 0,
  });

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
        sparkline: {
          enabled: true, // this will hide everything except the primary plot
        },
      },
      stroke: {
        curve: 'smooth' // to make the line chart smooth
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        labels: {
          show: false // hide x-axis labels
        },
        axisBorder: {
          show: false // hide x-axis border
        },
        axisTicks: {
          show: false // hide x-axis ticks
        }
      },
      yaxis: {
        labels: {
          show: false // hide y-axis labels
        },
        axisBorder: {
          show: false // hide y-axis border
        },
        axisTicks: {
          show: false // hide y-axis ticks
        }
      },
      grid: {
        show: false, // hide grid
      },
      dataLabels: {
        enabled: false, // hide data labels
      },
      tooltip: {
        enabled: false, // hide tooltip
      },
      legend: {
        show: false, // hide legend
      },
    },
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 120]
      }
    ]
  })

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = Cookies.get('jwt');
        const response = await axiosInstance.get(`${API_BASE_URL}/stats`, { ignore404: true });

        setStats(response.data);
      } catch (error) {
        console.error('Failed to fetch stats:', error);
      }
    };

    fetchStats();
  }, []);

  return (
    <>
    <div>
      <Row gutter={{ xs: 8, sm: 16, md: 24 }} style={{ margin: '30px' }}>
        <Col xs={12} sm={12} md={6}>
          <Card style={{ borderRadius: '25px', background: 'linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)), rgb(255, 255, 255)' }}>
            <Typography>
              <Paragraph style={{ textAlign: 'left' }}>Income</Paragraph>
              <Title level={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>$15,000.00</Title>
              <Paragraph style={{ textAlign: 'left' }}>+2.6% than last month</Paragraph>
            </Typography>
            <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            width="100%"
          />
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6}>
          <Card style={{ borderRadius: '25px', background: 'linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)), rgb(255, 255, 255)' }}>
            <Typography>
              <Paragraph style={{ textAlign: 'left' }}>Expenses</Paragraph>
              <Title level={2} style={{ fontWeight: 'bold', textAlign: 'left' }}>$8,827.00</Title>
              <Paragraph style={{ textAlign: 'left' }}>-0.5% than last month</Paragraph>
            </Typography>
            <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            width="100%"
          />
          </Card>
        </Col>
      </Row>
    </div>
    <div className="site-card-wrapper">
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Total Orders">
            <Statistic value={stats.totalOrders} />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Orders Due This Month">
            <Statistic value={stats.ordersDueThisMonth} />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total Orders This Month">
            <Statistic value={stats.totalOrdersThisMonth} />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Orders Due in Under 10 Days">
            <Statistic value={stats.ordersDueUnder10Days} />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Orders Due in Under 30 Days">
            <Statistic value={stats.ordersDueUnder30Days} />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Orders Due in Under 60 Days">
            <Statistic value={stats.ordersDueUnder60Days} />
          </Card>
        </Col>
      </Row>
    </div>
    </>
  );
};

export default Home;