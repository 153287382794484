import React, {useEffect} from 'react';
import LoginForm from './LoginForm/LoginForm';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const {isAuthenticated} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/orders');
        }
    }, []);
    return (
        <div>
        <h1>Login Page</h1>
            <LoginForm />
        </div>
    );
}

export default LoginPage;