import React, { useState, useEffect } from 'react';
import { Button, Table, Input, Checkbox, Form, message, Modal } from 'antd';
import axiosInstace from '../utils/axios';
import API_BASE_URL from '../config';

const PermissionsPage = () => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRolesAndPermissions = async () => {
      const rolesRes = await axiosInstace.get(`${API_BASE_URL}/api/users/roles`);
      const permissionsRes = await axiosInstace.get(`${API_BASE_URL}/api/users/permissions`);
      setRoles(rolesRes.data);
      setPermissions(permissionsRes.data);
      setLoading(false);
    };

    fetchRolesAndPermissions();
  }, []);

  const addPermission = async (values) => {
    try {
      const res = await axiosInstace.post(`${API_BASE_URL}/api/users/permissions`, values);
      setPermissions([...permissions, res.data]);
      message.success('Permission added');
    } catch (error) {
      message.error('Failed to add permission');
    }
  };

  const confirmChange = (confirmMessage, onOk) => {
    Modal.confirm({
      title: 'Are you sure?',
      content: confirmMessage,
      onOk,
      onCancel() {},
    });
  };
  
  const assignPermissionsToRole = async (roleId, permissionIds, action, permissionName, permissionDescription) => {
    const actionText = action === 'add' ? 'add' : 'remove';
    const confirmMessage = `Are you sure you want to ${actionText} the "${permissionName}" permission from this user role? The user role will ${actionText === 'add' ? 'be able to' : 'not be able to'} ${permissionDescription}.`;

  
    confirmChange(confirmMessage, async () => {
      try {
        await axiosInstace.put(`${API_BASE_URL}/api/users/roles/${roleId}/permissions`, { permissions: permissionIds });
        const updatedRoleIndex = roles.findIndex(role => role._id === roleId);
        if (updatedRoleIndex !== -1) {
          const updatedRoles = [...roles];
          updatedRoles[updatedRoleIndex] = {
            ...updatedRoles[updatedRoleIndex],
            permissions: permissionIds.map(id => permissions.find(permission => permission._id === id)),
          };
          setRoles(updatedRoles);
        }
        message.success('Permissions updated');
      } catch (error) {
        message.error('Failed to update permissions');
      }
    });
  };

  const columns = [
    {
      title: 'Role',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (text, record) => (
        permissions.map((permission) => {
          return (
            <Checkbox
              key={permission._id}
              checked={record.permissions.map(p => p._id).includes(permission._id)}
              onChange={(e) => {
                if (e.target.checked) {
                  assignPermissionsToRole(record._id, [...record.permissions.map(p => p._id), permission._id], 'add', permission.name, permission.description);
                } else {
                  assignPermissionsToRole(record._id, record.permissions.map(p => p._id).filter(id => id !== permission._id), 'remove', permission.name, permission.description);
                }
              }}
            >
              {permission.name}
            </Checkbox>
          );
        })
      ),
    },
  ];

  return (
    <div>
      <Form layout="inline" onFinish={addPermission}>
        <Form.Item name="name" rules={[{ required: true, message: 'Please input the permission name!' }]}>
          <Input placeholder="Permission name" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Add Permission</Button>
        </Form.Item>
      </Form>
      <Table loading={loading} columns={columns} dataSource={roles} rowKey="_id" />
    </div>
  );
};

export default PermissionsPage;
