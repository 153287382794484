import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import '../../node_modules/antd/dist/reset.css';
//import './index.css';

class Calendar extends React.Component {
  state = {
    date: dayjs(),
  };

  handleDateChange = (date) => {
    this.setState({
      date,
    });
  };

  render() {
    const { date } = this.state;

    return (
      <div className="calendar">
        <DatePicker onChange={this.handleDateChange} value={date} picker="month" />
        <div className="calendar-grid">
          {Array(31)
            .fill()
            .map((_, i) => (
              <div className={`day ${i < dayjs(date).endOf('month').date() ? 'active' : ''}`} key={i}>
                {i + 1}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default Calendar;
