//Bug to fix:
// 1. When you add a new subcategory, the new subcategory is not being displayed until page refresh
import React, { useState, useEffect } from 'react';
import { Button, Col, Row, List, Modal, Form, Input, Select, message, Card } from 'antd';
import axiosInstance from '../utils/axios';
import API_BASE_URL from '../config';
import { PlusOutlined, EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const { Option } = Select;

const CategoryItem = ({ category, handleDeleteCategory, fetchCategories }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [newName, setNewName] = useState(category.name);
  const [editedSubcategories, setEditedSubcategories] = useState(category.children.map(child => ({ ...child })));
  const [editModeSubcategory, setEditModeSubcategory] = useState({});

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(editedSubcategories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setEditedSubcategories(items);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      await axiosInstance.put(`/api/categories/${category._id}`, { name: newName });
      setIsEditMode(false);
      fetchCategories();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCancelClick = () => {
    setIsEditMode(false);
    setNewName(category.name);
  };

  const handleSubcategoryNameChange = (e, id) => {
    setEditedSubcategories(editedSubcategories.map(child => {
      if (child._id === id) {
        return { ...child, name: e.target.value };
      }
      return child;
    }));
  };

  const handleSubcategoryEditClick = (id) => {
    setEditModeSubcategory({ [id]: true });
  };

  const handleSubcategorySaveClick = async (id) => {
    const subcategory = editedSubcategories.find(child => child._id === id);

    try {
      await axiosInstance.put(`/api/categories/${subcategory._id}`, { name: subcategory.name });
      setEditModeSubcategory({ [id]: false });
      fetchCategories();
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubcategoryCancelClick = (id) => {
    setEditModeSubcategory({ [id]: false });
    setEditedSubcategories(category.children.map(child => ({ ...child })));
  };


  return (
    <Card
      style={{ width: 'max-content', maxWidth: '300px' }} // added margin here
      title={isEditMode ? (
        <Input value={newName} onChange={handleNameChange} />
      ) : (
        category.name
      )}
      extra={
        <>
          {isEditMode ? (
            <>
              <Button icon={<CheckOutlined style={{ color: 'green' }} />} onClick={handleSaveClick} style={{ border: 'none' }} />
              <Button icon={<CloseOutlined style={{ color: 'blue' }} />} onClick={handleCancelClick} style={{ border: 'none' }} />
              <Button icon={<DeleteOutlined style={{ color: 'red' }} />} onClick={() => handleDeleteCategory(category._id)} style={{ border: 'none' }} />
            </>
          ) : (
            <Button icon={<EditOutlined />} onClick={handleEditClick} style={{ border: 'none' }} />
          )}
        </>
      }
    >
       <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {editedSubcategories.map((child, index) => (
                <Draggable key={child._id.toString()} draggableId={child._id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <List.Item 
                    key={child._id.toString()}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                      {editModeSubcategory[child._id] ? (
                        <Input value={child.name} onChange={(e) => handleSubcategoryNameChange(e, child._id)} />
                      ) : (
                        child.name
                      )}
                      {editModeSubcategory[child._id] ? (
                        <>
                          <Button icon={<CheckOutlined />} onClick={() => handleSubcategorySaveClick(child._id)} style={{ border: 'none', color: 'green' }} />
                          <Button icon={<CloseOutlined />} onClick={() => handleSubcategoryCancelClick(child._id)} style={{ border: 'none', color: 'blue' }} />
                          <Button icon={<DeleteOutlined />} onClick={() => handleDeleteCategory(child._id)} style={{ border: 'none', color: 'red' }} />
                        </>
                      ) : (
                        <Button icon={<EditOutlined />} onClick={() => handleSubcategoryEditClick(child._id)} style={{ border: 'none' }} />
                      )}
                    </List.Item>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Card>
  );
};


const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/api/categories/getAll`);
      const hierarchicalData = createHierarchicalData(response.data);
      setCategories(hierarchicalData);
    } catch (err) {
      console.log(err.message);
    }
  };

  const createHierarchicalData = (flatData) => {
    let categoryMap = new Map();
    let rootCategories = [];

    flatData.forEach(category => {
      categoryMap.set(category._id, { ...category, children: [] });
    });

    categoryMap.forEach(category => {
      if (category.parent) {
        const parent = categoryMap.get(category.parent);
        parent.children.push(category);
      } else {
        rootCategories.push(category);
      }
    });

    rootCategories = rootCategories.filter(category => !category.parent);

    return rootCategories;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleAddCategory = async (values) => {
    try {
      await axiosInstance.post(`${API_BASE_URL}/api/categories`, values);
      await fetchCategories();
      setIsModalVisible(false);
      form.resetFields();
      message.success('Category added successfully!');
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditCategory = (id) => {
    // Code to edit a category by id
  };

  const handleDeleteCategory = (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this category?',
      onOk: async () => {
        try {
          await axiosInstance.delete(`${API_BASE_URL}/api/categories/${id}`);
          setCategories(categories.filter(category => category._id !== id));
        } catch (err) {
          console.error(err);
        }
      },
    });
  };

  const flattenCategories = (categories, level = 0) => {
    return categories.reduce((accumulator, category) => {
      const updatedCategory = { ...category, name: '-'.repeat(level) + category.name };
      return accumulator.concat(updatedCategory, flattenCategories(category.children, level + 1));
    }, []);
  };

  const renderCategory = (category, level = 0) => {
    const isParentCategory = category.children.length > 0;

    const categoryInfo = (
      <Card title={category.name} style={{ marginTop: '20px' }} extra={<Button icon={<EditOutlined />} onClick={() => handleEditCategory(category._id)} />}>
        {isParentCategory && category.children.map(child => <p key={child._id}>{child.name}</p>)}
        {!isParentCategory && (
          <Button icon={<DeleteOutlined />} onClick={() => handleDeleteCategory(category._id)}>
            Delete
          </Button>
        )}
      </Card>
    );

    return (
      <List.Item key={category._id}>
        {categoryInfo}
      </List.Item>
    );
  };

  return (
    <>
      <h1>Categories</h1>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showModal}
        shape="circle"
        size="large"
        style={{
          fontSize: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '70px',
          height: '70px',
          position: 'fixed',
          right: '20px',
          bottom: '20px',
          zIndex: '1000'
        }}
      />
      <Modal title="Add New Category" open={isModalVisible} onCancel={() => setIsModalVisible(false)} onOk={() => form.submit()}>
        <Form form={form} onFinish={handleAddCategory}>
          <Form.Item name="name" rules={[{ required: true, message: 'Please input the category name!' }]}>
            <Input placeholder="Category Name" />
          </Form.Item>
          <Form.Item name="parent" rules={[{ message: 'Please select the parent category!' }]}>
            <Select placeholder="Select parent category" allowClear>
              {flattenCategories(categories).map(category => (
                <Option key={category._id} value={category._id}>{category.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <div style={{ maxWidth: '80%', margin: 'auto' }}>
        <Row gutter={16}>
          {categories.map(category => (
            <Col key={category._id} xs={24} sm={12} md={8} lg={6} xl={4} style={{ marginRight: '100px' }}>
              <CategoryItem category={category} handleDeleteCategory={handleDeleteCategory} fetchCategories={fetchCategories} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};


export default CategoriesPage;
