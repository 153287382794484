import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './layout/MainLayout';

import 'antd/dist/reset.css';
import './App.css';

import Home from './pages/Home';
import AddNewOrder from './pages/AddNewOrder';
import Orders from './pages/Orders';
import OrderDetail from './pages/OrderDetail';
import EditOrder from './pages/EditOrder';
import LoginPage from './pages/LoginPage';
import PermissionsPage from './pages/Permissions';
import UsersPage from './pages/Users';
import CustomersPage from './pages/Customers';
import CustomerDetailPage from './pages/CustomerDetail';
import ProductsPage from './pages/ProductsPage';
import CategoriesPage from './pages/CategoriesPage';
import AttributesPage from './pages/AttributesPage';
import CalendarPage from './pages/CalendarPage';
import TestCalendar from './pages/TestCalendar';
import EditCustomerForm from './pages/edit/EditCustomer';
import PasswordChangeForm from './pages/PasswordChangeForm';
import DeliveredOrders from './pages/DeliveredOrders';
import AddNewOffer from './pages/AddNewOffer';

const AppRoutes = () => {
    return (
        <Router>
            <MainLayout>
                <Routes>
                    <Route
                        index
                        element={
                            <ProtectedRoute>
                                <Home />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/addNewOrder"
                        element={
                            <ProtectedRoute>
                                <AddNewOrder />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/calendar"
                        element={
                            <ProtectedRoute>
                                <CalendarPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/calendar-test"
                        element={
                            <ProtectedRoute>
                                <TestCalendar />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/orders"
                        element={
                            <ProtectedRoute>
                                <Orders />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/orders/delivered"
                        element={
                            <ProtectedRoute>
                                <DeliveredOrders />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/orders/:orderId"
                        element={
                            <ProtectedRoute>
                                <OrderDetail />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/orders/:orderId/edit"
                        element={
                            < ProtectedRoute >
                                <EditOrder />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/permissions"
                        element={
                            < ProtectedRoute >
                                <PermissionsPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/users"
                        element={
                            < ProtectedRoute >
                                <UsersPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/clients"
                        element={
                            < ProtectedRoute >
                                <CustomersPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/add-new-offer"
                        element={
                            < ProtectedRoute >
                                <AddNewOffer />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/clients/:clientId"
                        element={
                            <ProtectedRoute>
                                <CustomerDetailPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/clients/edit/:clientId"
                        element={
                            <ProtectedRoute>
                                <EditCustomerForm />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/catalog/categorii"
                        element={
                            <ProtectedRoute>
                                <CategoriesPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/catalog/produse"
                        element={
                            <ProtectedRoute>
                                <ProductsPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/catalog/atribute"
                        element={
                            <ProtectedRoute>
                                <AttributesPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/settings/password"
                        element={
                            <ProtectedRoute>
                                <PasswordChangeForm />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/login" element={<LoginPage />} />
                </Routes>
            </MainLayout>
        </Router >
    )
}

export default AppRoutes;