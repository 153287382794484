import React, { useState, useEffect } from 'react';
import { Button, Card, List, Divider, Tag, Modal } from 'antd';
import { ClockCircleOutlined, CalendarOutlined, UserOutlined, DeleteOutlined, EditOutlined, CheckCircleOutlined, CheckOutlined } from '@ant-design/icons';
import axiosInstance from '../utils/axios';
import { Link } from 'react-router-dom';
import calculateDaysLeft, { dateDfference } from '../utils/timeHelper';
import formatDate from '../utils/formatDate';
import API_BASE_URL from '../config';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const Orders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);

  const handleMarkAsDelivered = async (orderId, orderStatus) => {
    const newStatus = orderStatus === 'Delivered' ? 'Active' : 'Delivered';

    Modal.confirm({
      title: 'Confirm Delivery',
      content: `Are you sure you want to tag this order as ${newStatus}?`,
      onOk: async () => {
        try {
          // Retrieve order information from backend
          const orderResponse = await axiosInstance.get(`${API_BASE_URL}/api/orders/${orderId}`);
          const order = orderResponse.data;

          const startDate = order.dateOrderConfirmed;
          const endDate = newStatus === 'Delivered' ? new Date() : order.dateDelivered;
          console.log('Start date: ', startDate);
          console.log('End date: ', endDate);
          //const numberOfDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
          const numberOfDays = dayjs(endDate).diff(dayjs(startDate), 'day');

          const response = await axiosInstance.put(`${API_BASE_URL}/api/orders/${orderId}/markAs${newStatus}`, { numberOfDays });
          setOrders(orders.map(order => order._id === orderId ? response.data : order));
        } catch (err) {
          console.error(err);
        }
      },
    });
};

  const handleEditOrder = (orderId) => {
    // code to redirect to the edit order page
    navigate(`/orders/edit/${orderId}`);
  };

  const handleDeleteOrder = (orderId) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this order?',
      onOk: async () => {
        try {
          const response = await axiosInstance.delete(`${API_BASE_URL}/api/orders/delete/${orderId}`);
          console.log(response.data);
          setOrders(orders.filter(order => order._id !== orderId));
        } catch (err) {
          console.log(err.message);
        }
      }
    });
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/api/orders/getAll`, { ignore404: true });
        console.log(response.data);
  
        const sortedOrders = response.data.sort((a, b) => {
          const aDueDate = dayjs(a.dueDate);
          const bDueDate = dayjs(b.dueDate);
  
          // Sort in ascending order of due dates
          // If a's due date is before b's, it comes first and vice versa
          return aDueDate.isBefore(bDueDate) ? -1 : aDueDate.isAfter(bDueDate) ? 1 : 0;
        });
  
        setOrders(sortedOrders);
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchOrders();
  }, []);

  return (
    <>
      <h1>Toate comenzile</h1>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        dataSource={orders.filter(order => order.orderStatus !== 'Delivered')}
        renderItem={item => {
          const { daysLeft, color } = calculateDaysLeft(item.dueDate, item.dateOrderConfirmed);
          const productionDays = dateDfference(item.dateDelivered, item.dateOrderConfirmed);
          console.log('Production days: ', productionDays);
          // Card style based on the order status
          const cardStyle = item.orderStatus === 'Delivered' ? { opacity: 0.5 } : {};

          return (
            <List.Item>
              <Card style={cardStyle}>
                <Link to={`/orders/${item._id}`}>
                  <div style={{
                    height: '200px',
                    backgroundImage: item.products[0] && item.products[0].pictures[0] ? `url(${API_BASE_URL}/${item.products[0].pictures[0].path}/thumbnail/${item.products[0].pictures[0].filename})` : '',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain'
                  }} />
                </Link>
                <h2><Link to={`/orders/${item._id}`} style={{ color: 'black' }}>{item.title}</Link></h2>
                <Divider />
                <div>
                  <Button icon={<CheckCircleOutlined />} onClick={() => handleMarkAsDelivered(item._id, item.orderStatus)}>
                    {item.orderStatus === 'Delivered' ? 'Active' : 'Delivered'}
                  </Button>
                  <Button icon={<EditOutlined />} onClick={() => handleEditOrder(item._id)}>Editeaza</Button>
                  <Button icon={<DeleteOutlined />} onClick={() => handleDeleteOrder(item._id)}>Sterge</Button>
                </div>
                <Divider />
                <p><UserOutlined /> Client: <strong>{item.numeClient}</strong></p>
                <p><CalendarOutlined /> Data comenzii: <strong>{formatDate(new Date(item.dateOrderConfirmed))}</strong></p>
                <p><ClockCircleOutlined /> Data limita: <strong>{formatDate(new Date(item.dueDate))}</strong></p>
                <p><Tag color="green">Produse:</Tag></p>
                <ul>
                  {item.products.map(product => (
                    <li key={product._id}>
                      {product.name} ({product.material})
                    </li>
                  ))}
                </ul>
                {
                  item.orderStatus === 'Delivered' && item.dateDelivered
                    ? (
                      <>
                        <h2> - Livrat in data: {formatDate(new Date(item.dateDelivered))} -</h2>
                        <h2> - Durata productie: {productionDays} zile -</h2>
                      </>
                    )
                    : <h2 style={{ color }}> - {daysLeft < 0 ? "Termen depasit cu:" : "Timp ramas:"} {daysLeft} zile -</h2>
                }
                <Button type="primary" block>
                  <Link to={`/orders/${item._id}`}>Detalii comanda</Link>
                </Button>
                {item.orderStatus === 'Delivered' && <CheckOutlined style={{ fontSize: '2em', color: 'green', position: 'absolute', right: '10px', top: '10px' }} />}
              </Card>
            </List.Item>
          )
        }}
      />
    </>
  );
};

export default Orders;
