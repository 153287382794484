import axios from "axios";
import Cookies from "js-cookie";
import { Modal } from 'antd';
import jwt_decode from 'jwt-decode';
import setDeviceName from './setDevice';
import API_BASE_URL from '../config';

function showErrorModal(title, content) {
    Modal.error({
        title: title,
        content: content,
    });
}

const instance = axios.create({
    baseURL: API_BASE_URL + '/api',
    withCredentials: true,
});

const refreshAccessToken = async (error) => {
    try {
        console.log('refreshing access token in axios instance')
        const token = Cookies.get('token');
        const refreshToken = Cookies.get('refreshToken');
        const device = setDeviceName();
        if (!refreshToken) {
            showErrorModal('Error', "Please login again.");
            return Promise.reject(error);
        }

        if (!token || token === 'undefined') {
            showErrorModal('Error', "Please login again.");
            return Promise.reject(error);
        }

        const decoded = jwt_decode(token);
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {

            try {
                const response = await axios.post(
                    `${instance.defaults.baseURL}/auth/refresh-token`,
                    { refreshToken, oldToken: token, device },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const newToken = response.data.token;

                if (newToken) {
                    Cookies.set("token", response.data.token);
                    Cookies.set("refreshToken", response.data.refreshToken);
                }
                error.config.headers['Authorization'] = 'Bearer ' + response.data.token;
                return instance(error.config);
            } catch (err) {
                showErrorModal('Error', "Error refreshing token.");
                return Promise.reject(err);
            }
        }
    } catch (error) {
        showErrorModal('Error', "Error refreshing access token.");
        return Promise.reject(error);
    }
};

instance.interceptors.request.use((config) => {
    const token = Cookies.get('token');

    // Attach the token to the Authorization header if it exists
    if (token && config.auth !== false) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        let errorMessage = 'Please try again later.';
        let errorTitle = 'Error';
        if (error.response) {
            console.log(JSON.stringify(error.response.data.title) + ' error response');
            switch (error.response.status) {
                case 400:
                    errorMessage = error.response.data.message || 'The server could not understand the request due to invalid syntax.';
                    errorTitle = error.response.data.title || 'Bad Request';
                    showErrorModal(errorTitle, errorMessage);
                    break;
                case 401:
                    // Call refreshAccessToken when Unauthorized error occurs
                    return refreshAccessToken(error);
                case 403:
                    errorMessage = error.response.data.message || 'You do not have the necessary permissions to access the requested resource.';
                    showErrorModal('Forbidden', errorMessage);
                    break;
                case 404:
                    if (!error.config.ignore404) {
                        errorMessage = error.response.data.message || 'The requested resource could not be found on the server.';
                        showErrorModal('Not Found', errorMessage);
                    }
                    break;
                case 500:
                    errorMessage = error.response.data.message || 'The server encountered an error and could not complete your request.';
                    showErrorModal('Internal Server Error', errorMessage);
                    break;
                default:
                    errorMessage = error.response.data.message || 'An error occurred';
                    showErrorModal('An error occurred', errorMessage);
            }
        } else if (error.request) {
            showErrorModal('No Response', 'The request was made but no response was received.');
        } else {
            showErrorModal('Unexpected Error', 'An unexpected error occurred.');
        }

        return Promise.reject(error);
    }
);

export default instance;
