import React, { useState } from 'react';
import { Breadcrumb, Layout, theme } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import AppSider from './AppSider';

const { Header, Content, Footer } = Layout;

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(window.innerWidth > 768);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const location = useLocation();
  const pathNames = location.pathname.split('/').filter((name) => name !== '');

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // create the breadcrumb items
  const breadcrumbItems = pathNames.map((part, index) => {
    const path = `/${pathNames.slice(0, index + 1).join('/')}`;
    const isCurrentPage = path === location.pathname;
    return {
      key: index,
      content: isCurrentPage ? capitalizeFirstLetter(part) : <Link to={path}>{capitalizeFirstLetter(part)}</Link>
    };
  });

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppSider collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 200 }}>
        <Header style={{ padding: 0, background: colorBgContainer }} />
        <Content style={{ margin: '0 16px' }}>
          <Breadcrumb style={{ margin: '16px 0' }} items={breadcrumbItems} />
          <div style={{ padding: 24, minHeight: 360 }}>{children}</div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>neroinvest ©2023 Created by proquest</Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
