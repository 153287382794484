import React, { useState, useRef } from 'react';
import { Form, Input, Button, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import axiosInstance from '../utils/axios';
import API_BASE_URL from '../config';


const PasswordChangeForm = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const formRef = useRef();

    const onFinish = async (values) => {
        if (newPassword !== confirmNewPassword) {
            message.error("New passwords don't match");
            return;
        }

        try {
            await axiosInstance.put(`${API_BASE_URL}/api/users/change-password`, {
                oldPassword,
                newPassword,
            });
            message.success('Password changed successfully');
            // Reset input fields after successful request
            formRef.current.resetFields();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const { errors } = error.response.data;
                if (errors.password && errors.password.kind === 'minlength') {
                    message.error('Your password is too short. The minimum length is 6 characters.');
                } else {
                    message.error('Failed to change password');
                }
            } else {
                message.error('Failed to change password');
            }
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="password-change-form">
            <h1>Change Password</h1>
            <Form
                ref={formRef}
                labelCol={{
                    span: 4
                }}
                wrapperCol={{
                    span: 16
                }}
                style={{
                    maxWidth: 500,
                }}
                name="change_password"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    name="oldPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your old password!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Old Password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="confirmNewPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your new password!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Confirm New Password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="password-change-form-button">
                        Change Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default PasswordChangeForm;
