// UsersPage.js
import React, { useState, useEffect } from 'react';
import { Button, Table,  Input, Form, message, Select, Popconfirm } from 'antd';
import axiosInstance from '../utils/axios';
import API_BASE_URL from '../config';
import Cookies from 'js-cookie';

const { Option } = Select;

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(Cookies.get('token'));
  const [form] = Form.useForm();

  useEffect(() => {
    if(!token) {
        setToken(Cookies.get('token'));
    }
    const fetchData = async () => {
      const usersRes = await axiosInstance.get(`${API_BASE_URL}/api/users`);
      const rolesRes = await axiosInstance.get(`${API_BASE_URL}/api/users/roles`);
      console.log('user data ' + usersRes.data);
      console.log(rolesRes.data);
      setUsers(usersRes.data);
      setRoles(rolesRes.data);
      setLoading(false);
    };

    fetchData();
  }, [token]);

  const addUser = async (values) => {
    try {
      const res = await axiosInstance.post(`${API_BASE_URL}/api/users`, values);
      setUsers([...users, res.data]);
      message.success('User added');
    } catch (error) {
      message.error('Failed to add user');
    }
  };

  const editUser = async (values) => {
    try {
      const res = await axiosInstance.put(`${API_BASE_URL}/api/users/${currentUser._id}`, values);
      setUsers(users.map(user => user._id === currentUser._id ? res.data : user));
      message.success('User updated');
      setVisible(false);
      form.resetFields();
    } catch (error) {
      message.error('Failed to update user');
    }
  };

  const deleteUser = async (userId) => {
    try {
      await axiosInstance.delete(`${API_BASE_URL}/api/users/${userId}`);
      setUsers(users.filter(user => user._id !== userId));
      message.success('User deleted');
    } catch (error) {
      message.error('Failed to delete user');
    }
  };

  const handleEdit = (user) => {
    setCurrentUser(user);
    form.setFieldsValue({ ...user, role: user.role._id });
    setVisible(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: role => role.name,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteUser(record._id)}
          >
            <Button>Delete</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <Form layout="inline" onFinish={addUser} form={form}>
        <Form.Item name="name" rules={[{ required: true, message: 'Please input the user name!' }]}>
          <Input placeholder="User name" />
        </Form.Item>
        <Form.Item name="email" rules={[{ required: true, message: 'Please input the user email!', type: 'email' }]}>
          <Input placeholder="User email" />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true, message: 'Please input the user password!' }]}>
          <Input type="password" placeholder="User password" />
        </Form.Item>
        <Form.Item name="role" rules={[{ required: true, message: 'Please select a role!' }]}>
          <Select placeholder="Role">
            {roles.map(role => (
              <Option value={role._id} key={role._id}>{role.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">{currentUser ? 'Edit User' : 'Add User'}</Button>
        </Form.Item>
      </Form>
      <Table loading={loading} columns={columns} dataSource={users} rowKey="_id" />
    </div>
  );
};

export default UsersPage;
