import React, { useState, useEffect } from 'react';
import { Button, Card, List, Divider, Modal, message, Spin } from 'antd';
import { UserOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import axiosInstance from '../utils/axios';
import { Link } from 'react-router-dom';
import API_BASE_URL from '../config';
import { useNavigate } from 'react-router-dom';
import formatDate from '../utils/formatDate';
import NewCustomerForm from './NewCustomerForm';

const Customers = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { confirm } = Modal;

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleNewCustomer = (newCustomer) => {
        setCustomers(prevCustomers => [...prevCustomers, newCustomer]);
        setIsModalVisible(false);
    };

    const handleEditCustomer = (customerId) => {
        // code to redirect to the edit customer page
        navigate(`/clients/edit/${customerId}`);
    };

    const handleDeleteCustomer = (customerId) => {
        confirm({
            title: 'Are you sure delete this customer?',
            content: 'This action cannot be undone',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                try {
                    const response = await axiosInstance.delete(`${API_BASE_URL}/api/customers/delete/${customerId}`);
                    console.log(response.data); // Log the server response to the console
                    if (response.status === 200) {
                        setCustomers(customers.filter(customer => customer._id !== customerId)); // Update the customers list
                        message.success('Customer deleted successfully!');  // <-- success message
                    } else {
                        throw new Error(`Server responded with status code ${response.status}`);
                    }
                } catch (error) {
                    console.error(error);
                    message.error('An error occurred while deleting the customer.');  // <-- error message
                }
            },
            onCancel() {
                console.log('Delete action cancelled');
            },
        });
    };

    useEffect(() => {
        const fetchCustomers = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.get(`${API_BASE_URL}/api/customers/getAll`, { ignore404: true });
                console.log(response.data);
                setCustomers(response.data);
            } catch (err) {
                console.log(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCustomers();
    }, []);

    return (
       <>
        {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" />
            </div>
        ) : (
            <>
            <h1>All Customers</h1>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={showModal}
                shape="circle"
                size="large"
                style={{
                    fontSize: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '70px',
                    height: '70px',
                    position: 'fixed',
                    right: '20px',
                    bottom: '20px'
                }}
            />
            <Modal title="New Customer" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <NewCustomerForm onCustomerCreated={handleNewCustomer} />
            </Modal>
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 4,
                    xxl: 4
                }}
                dataSource={customers}
                renderItem={item => {
                    return (
                        <List.Item>
                            <Card>
                                <h2><Link to={`/clients/${item._id}`} style={{ color: 'black' }}>{item.name}</Link></h2>
                                <Divider />
                                <div>
                                    <Button icon={<EditOutlined />} onClick={() => handleEditCustomer(item._id)}>Edit</Button>
                                    <Button icon={<DeleteOutlined />} onClick={() => handleDeleteCustomer(item._id)}>Delete</Button>
                                </div>
                                <Divider />
                                <p><UserOutlined /> Name: <strong>{item.name}</strong></p>
                                <p>Orders:</p>
                                {console.log(item)}
                                <ul>
                                    {item.orders?.map((order, index) => (
                                        <li key={order._id || index}>
                                            {order.title}
                                        </li>
                                    ))}
                                </ul>
                                <p>Offers:</p>
                                <ul>
                                    {item.offers?.map((offer, index) => (
                                        <li key={offer._id || index}>
                                            {offer.title}
                                        </li>
                                    ))}
                                </ul>
                                <Button type="primary" block>
                                    <Link to={`/clients/${item._id}`}>Customer Details</Link>
                                </Button>
                            </Card>
                        </List.Item>
                    )
                }}
            />
            </>
        )}
        </>
    );
};

export default Customers;