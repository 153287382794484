import React, { useState } from 'react';
import { Form, Input, Button, Radio, Collapse, message  } from 'antd';
import axiosInstance from '../utils/axios';
import API_BASE_URL from '../config';

const { Panel } = Collapse;

const NewCustomerForm = ({ onCustomerCreated }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [invoicingType, setInvoicingType] = useState('company'); // default value

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await axiosInstance.post(`${API_BASE_URL}/api/customers/create`, values);
            form.resetFields();
            onCustomerCreated(response.data);
            message.success('Customer created successfully!');  // <-- success message
        } catch (error) {
            console.error(error);
            message.error('An error occurred while creating the customer.');  // <-- error message
        } finally {
            setLoading(false);
        }
    };

    const onInvoicingTypeChange = e => {
        setInvoicingType(e.target.value);
    };

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Collapse accordion>
                <Panel header="Informatii generale" key="1">
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Campul denumire companie este obligatoriu!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Telefon" name="phones">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Email" name="emails">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Adresa" name="address">
                        <Input />
                    </Form.Item>
                </Panel>
                <Panel header="IDate facturare" key="2">
                    <Form.Item label="Invoicing Type" name={['invoicing', 'type']}>
                        <Radio.Group onChange={onInvoicingTypeChange}>
                            <Radio value="company">Firma</Radio>
                            <Radio value="person">Persoana fizica</Radio>
                            <Radio value="institution">Institutie publica</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {((invoicingType === 'company' || invoicingType === 'institution')) && (
                        <>
                            <Form.Item label="Denumire companie" name={['invoicing', 'company', 'name']}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Registration Number" name={['invoicing', 'company', 'registrationNumber']}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="VAT Number" name={['invoicing', 'company', 'vatNumber']}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Company Address" name={['invoicing', 'company', 'address']}>
                                <Input />
                            </Form.Item>
                        </>
                    )}
                    {invoicingType === 'person' && (
                        <>
                            <Form.Item label="Person Name" name={['invoicing', 'person', 'name']}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Person Address" name={['invoicing', 'person', 'address']}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="ID Serial Number" name={['invoicing', 'person', 'idSerialNumber']}>
                                <Input />
                            </Form.Item>
                        </>
                    )}
                    {/* Add further conditions here for the "institution" invoicing type, if needed. */}
                </Panel>
                <Panel header="Delivery Data" key="3">
                    <Form.Item label="Country" name={['delivery', 'country']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="City" name={['delivery', 'city']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Street Name" name={['delivery', 'streetName']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Number" name={['delivery', 'number']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Other Address Data" name={['delivery', 'otherAddressData']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Contact Person" name={['delivery', 'contactPerson']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Phone Number" name={['delivery', 'phoneNumber']}>
                        <Input />
                    </Form.Item>
                </Panel>
            </Collapse>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Create
                </Button>
            </Form.Item>
        </Form>
    );
};

export default NewCustomerForm;
